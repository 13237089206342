/*========================FICHE FILM=========================*/

.section2-ff {
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}


.container-film {
    max-width: 1545px;
    margin: auto;
    margin-top: 175px;
}

.section1-ff {
    position: relative;
    min-height: 919px;
    overflow: hidden;
    color: white;
}

.section1-ff .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    filter: brightness(30%);
    z-index: -2;

}

.cover-ff img {
    height: 700px;
    max-width: 450px;
    border-radius: 20px;
    border: 1px solid white;
}

.titre {
    margin-top: 30px;
}

.titre h1 {
    font-size: 50px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    margin: 0;
}

.titre h2 {
    margin-top: 8px;
    margin-left: 22px;
    font-family: dubai;
    color: #C3F954;
    font-size: 40px;
}

.bull {
    color: #2971EE;
    font-size: 30px;
    margin: -10px 20px 0px 20px;
}

.sous-titre {
    font-family: dubai;
    font-size: 20px;
    display: flex;
    flex-wrap: wrap;
}

.heure {
    order: 2;
    ;
}

.like {
    margin-left: 140px;
    margin-top: 50px;
    font-family: dubai;
    font-size: 20px;
}

.résumé {
    color: #C3F954;
    font-family: dubai;
    font-size: 20px;
    margin-top: 50px;
    margin-bottom: 40px;
}

.synopsis {
    font-family: dubai;
    font-size: 20px;
}

.bande-annonce button {
    text-decoration: none;
    color: white;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 30px;
    border: 2px solid #C3F954;
    border-radius: 15px;
    padding: 10px;
    background-color: transparent;
    margin-top: 25px;
    margin-bottom: 25px;
}

.bande-annonce i {
    color: white;
    margin-left: 10px;
}

.titre-origine {
    margin-top: 100px;
}

.origine-bleu {
    color: #2971EE;
    font-family: dubai;
    font-size: 20px;
    margin-right: 10px;
}

.origine-blanc {
    font-size: 20px;
    font-family: dubai;
}

.catégories-ff {
    padding-right: 10px;
}

.section2-ff {
    font-family: dubai;
}

.arrow {
    color: #C3F954;
    text-align: end;
}




/*========BAR DE %========*/

.ProgressBar,
.ProgressBar-contentCircle {
    display: table;
    height: 125px;
    position: absolute;
    width: 125px;
}

.ProgressBar-circle,
.ProgressBar-background {
    fill: none;
    stroke: #2971EE;
    stroke-width: 6;
    stroke-linecap: square;
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
    position: relative;
    z-index: 10;
}

.ProgressBar-background {
    stroke: white;
    stroke-width: 5;
    z-index: 0;
}

.ProgressBar-percentage {
    color: #C3F954;
    font-size: 35px;
    text-align: center;
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    font-family: dubai;
}

.list {
    list-style: none;
}

.content-pourcentage {
    margin-left: -35px;
}


.ProgressBar-contentCircle text {
    fill: #C3F954;

}

.ProgressBar-circle {
    fill: none;
    stroke: #2971EE;
    stroke-width: 6;
    stroke-linecap: square;
    stroke-dasharray: 314; /* Périmètre approximatif du cercle (2 * π * r) */
    stroke-dashoffset: 314; /* Commence complètement masqué */
    transition: stroke-dashoffset 1s ease-out; /* Animation */
    position: relative;
    z-index: 10;
}


/*========POP UP BANDE-ANNONCE========*/

.lightbox:target {
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    position: relative;
    width: fit-content;
    max-width: 90%;
    max-height: 90%;
    background: #000;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}

iframe {
    border-radius: 15px;
}

/*========CARTES ANIMÉES========*/

.cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
    max-width: 700px;
    height: 275px;
    margin: auto;
}

.card {
    margin-left: 12px;
    margin-top: 30px;
    transition: transform 300ms ease-in-out, box-shadow 400ms ease, border 500ms linear;
    transform: scale(1);
    padding: 1rem;
    text-align: center;
    overflow: hidden;
    aspect-ratio: 3/4;
    display: flex;
    position: relative;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border: 1px solid #C3F954;
    width: 150px;
    height: 225px;
}

.card__content {
    margin: 0 auto;
    color: #fff;
}

.card__content h2 {
    color: #fff;
    font-size: 1.25rem;
    margin: 0;
    text-shadow: 0 2px 10px black;
}

.card__content p {
    font-size: 1rem;
}

.card__content .card__content-bg {
    transition: all 350ms ease-in-out;
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    top: 70%;
    left: 50%;
    right: 50%;
    bottom: 20%;
    z-index: 0;
}

.card__content img {
    transition: transform 750ms ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    max-width: 100%;
}

.card__content a {
    text-decoration: none;
    color: white;
}

.card__content h2,
.card__content p,
.card__content button {
    transition: transform 350ms ease-in-out;
    transform: translateY(80vh) scale(3.4);
    position: relative;
    z-index: 1;
}

.card__content h2 {
    transition: all 350ms ease-in-out;
    transform: translateY(6rem) scale(1);
}

.card__content p {
    transition-delay: 50ms;
}

.card:hover {
    transform: scale(1.15);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.75);
    border-radius: 15px;
    border: 1px solid #C3F954;
}

.card:hover .card__content h2,
.card:hover .card__content p,
.card:hover .card__content button {
    transform: translateY(0) scale(1);
}

.card:hover .card__content h2 {
    color: orange;
    font-size: 1.75rem;
}

.card:hover .card__content img {
    filter: brightness(60%);
}

.card:hover .card__content-bg {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.name {
    color: #C3F954;
    margin-top: 60px;
}

.card__image {
    width: 100%;
    aspect-ratio: 2/3;
    height: auto;
}

a {
    text-decoration: none;
}



/*========CAROUSEL-ACTEUR========*/

.section2-ff .splide__track {
    overflow: hidden;
}

.section2-ff .splide__slide {
    width: 100%;
    box-sizing: border-box;
    margin: 0 -220px;
}

.section2-ff .splide__pagination {
    bottom: initial;
}

.section2-ff .splide__pagination__page.is-active {
    background-color: #333;
}

.section2-ff .all svg,
.all button {
    display: none;
}

.section2-ff .splide.is-initialized {
    max-width: 1920px !important;
    overflow: hidden;
}


/*========================RESPONSIVE========================*/


@media only screen and (max-width: 1600px) {

    .words-ff {
        padding-right: 43px;
    }

}

@media only screen and (max-width: 992px) {

    .row {
        flex-wrap: wrap;
    }

    .titre h1 {
        margin-left: 15px;
    }

    .sous-titre {
        margin-left: 15px;
        margin-top: 20px;
    }

    .content-pourcentage {
        margin-left: -20px;
    }

    .résumé {
        margin-left: 15px;
    }

    .synopsis {
        margin-left: 15px;
    }

    .bande-annonce button {
        margin-left: 15px;
    }

    .origine-bleu {
        margin-left: 15px;
    }

    .like {
        margin-left: 175px;
    }

}


@media screen and (max-width: 900px) {
    .menu {
        display: none;
    }

    .menu-icon {
        display: block;
    }

    .nav {
        display: block;
    }

    .container-film {
        margin-top: 75px;
    }
}


@media screen and (min-width: 901px) {
    .menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }

    .menu-icon {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .nav__list-item {
        transform: translate(-100px, 0%);
    }

    body.nav-active .nav__list-item {
        transform: translate(0px, 0%);
    }
}



@media (max-width: 768px) {
    .footer .content-wrapper {
        display: block;
        text-align: center;
    }

    .footer .d-flex {
        display: block;
        text-align: center;
    }

    .footer .d-flex.justify-content-center {
        display: inline-block;
        text-align: center;
    }

    .footer .tmdb-logo {
        display: block;
        margin: 10px auto 0;
        text-align: center;
    }

    .footer .tmdb-logo img {
        margin: 0 auto;
    }
}


@media only screen and (max-width: 520px) {

    .cover-ff img {
        width: 350px;
        height: 525px;
    }

    .titre {
        margin-left: 15px;
    }

    .sous-titre {
        margin-left: 11px;
        display: block;
    }

    .heure {
        display: block;
    }

}