.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 106px;
  margin: 0 26px;
  border-radius: 15px;
  border: 2px solid #C3F954;
  background-color: black;
}

.menu-logo {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 90px;
  width: 90px;
  margin-left: 20px;
}

.logo-image-nav {
  height: 50px;
  width: 50px;
  margin-left: 20px;
  margin-top: 20px;
}

.menu-items {
  display: flex;
  align-items: center;
}

.menu-item {
  color: white;
  font-size: 30px;
  font-family: dubai;
  margin: 0 20px;
  text-decoration: none;
}

.icon{
  color: #2971EE;
  margin-right: 10px;
}

.menu-item:hover {
  color: #C3F954;
}

.menu-icon {
  height: 30px;
  width: 30px;
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
  display: none;
  z-index: 2;
}

.menu-icon__line {
  height: 2px;
  width: 30px;
  display: block;
  background-color: #fff;
  margin-bottom: 5px;
  transition: all 0.3s ease;
}

.menu-icon__line-left {
  width: 20px;
}

.menu-icon__line-right {
  width: 20px;
}

.arrow-menu {
  color: #C3F954;
  text-align: end;
  margin-top: -32px;
}

.nav {
  position: fixed;
  z-index: 98;
  display: none;
}

.nav__content {
  position: fixed;
  visibility: hidden;
  top: 50%;
  margin-top: 20px;
  transform: translate(0%, -50%);
  width: 100%;
  text-align: center;
  background: black;
}

.nav__list {
  position: relative;
  padding: 0;
  margin: 0;
  z-index: 2;
  margin: 220px 0px 415px 0px;
}

.nav__list-item {
  position: relative;
  display: block;
  opacity: 0;
  text-align: center;
  color: #fff;
  font-family: dubai;
  font-size: 30px;
  line-height: 1.15;
  letter-spacing: 3px;
  transform: translate(100px, 0%);
  transition: opacity .2s ease, transform .3s ease;
  margin-top: 0;
  margin-bottom: 0;
}

.nav__list-item a {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  transition: all 200ms linear;
  margin-top: 30px;
  color: white;
}

.nav__list-item a:after {
  position: absolute;
  content: '';
  top: 75%;
  margin-top: -2px;
  left: 50%;
  width: 0;
  height: 0;
  opacity: 0;
  background-color: #C3F954;
  transition: all 200ms linear;
}

.nav__list-item a:hover:after {
  height: 4px;
  opacity: 1;
  left: 0;
  width: 100%;
}

.nav__list-item a:hover {
  color: rgba(255, 255, 255, 1);
}

.nav__list-item.active-nav a {
  color: rgba(255, 255, 255, 1);
}

.nav__list-item.active-nav a:after {
  height: 4px;
  opacity: 1;
  left: 0;
  width: 100%;
}

body.nav-active .nav__content {
  visibility: visible;
}

body.nav-active .menu-icon__line {
  background-color: #fff;
  transform: translate(0px, 0px) rotate(-45deg);
}

body.nav-active .menu-icon__line-left {
  width: 15px;
  transform: translate(2px, 4px) rotate(45deg);
}

body.nav-active .menu-icon__line-right {
  width: 15px;
  float: right;
  transform: translate(-3px, -3.5px) rotate(45deg);
}

body.nav-active .menu-icon:hover .menu-icon__line-left,
body.nav-active .menu-icon:hover .menu-icon__line-right {
  width: 15px;
}

body.nav-active .nav {
  visibility: visible;
}

body.nav-active .nav__list-item {
  transform: translate(0px, 0%);
  opacity: 1;
}

body.nav-active .nav__list-item:nth-child(1) {
  transition-delay: 0.2s;
}

body.nav-active .nav__list-item:nth-child(2) {
  transition-delay: 0.3s;
}

body.nav-active .nav__list-item:nth-child(3) {
  transition-delay: 0.4s;
}

body.nav-active .nav__list-item:nth-child(4) {
  transition-delay: 0.5s;
}

.menu-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: white;
  font-size: 30px;
  z-index: 3;
}

.menu-close i {
  margin: 0;
  color: #fff;
  font-size: 30px;
}



@media screen and (max-width: 900px) {
  .menu {
      display: none;
  }

  .menu-icon {
      display: block;
  }

  .nav {
      display: block;
  }

  .container-film {
      margin-top: 75px;
  }
}


@media screen and (min-width: 901px) {
  .menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
  }

  .menu-icon {
      display: none;
  }
}

@media screen and (max-width: 767px) {
  .nav__list-item {
      transform: translate(-100px, 0%);
  }

  body.nav-active .nav__list-item {
      transform: translate(0px, 0%);
  }
}
