.movie {
    height: 150px;
    position: relative;
    margin: 15px;
    width: 22%;
    text-align: center;
    cursor: pointer;

    img {
        height: 130px;
        width: 270px;
        object-fit: cover;
        border-radius: 10px;
    }

    .conteneur {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        text-align: center;
        font-weight: bold;
        opacity: 0;
    }

    .conteneur:hover {
        opacity: 1;
    }
}

.movie:hover {
    transform: scale(1.5);
    z-index: 2;
}