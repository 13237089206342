/*================FILTRE================*/
.frame {
    display: none;
    margin: auto;
    margin-top: 10px;
    overflow-y: auto;
    color: white;
    height: 6em;
    width: 10em;
    line-height: 23px;
    font-family: dubai;
}

.frame a:hover {
    color: #C3F954;
}

.frame a {
    visibility: hidden;
}

.frame.show {
    display: block;
}

.filtre-responsive a {
    text-decoration: none;
    border: 1px solid #C3F954;
    color: #C3F954;
    font-family: dubai;
    font-size: 20px;
    padding: 10px 10px 10px 10px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
}

.filtre-responsive {
    visibility: hidden;
}

.frame::-webkit-scrollbar {
    -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
    width: 20px;
}

.frame::-webkit-scrollbar:horizontal {
    height: 20px;
}

.frame::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid black;
    background-color: rgba(0, 0, 0, .5);
}

form {
    margin-top: 200px;
    border: 1px solid #C3F954;
    border-radius: 15px;
    height: 650px;
    margin-left: 26px;
}


/*========================CATÉGORIES FILM========================*/


.section-all {
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.checkbox-custom,
.radio-custom {
    opacity: 0;
    position: absolute;
}

.checkbox-custom,
.checkbox-custom-label,
.radio-custom,
.radio-custom-label {
    width: 150px;
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
    color: white;
    margin-left: 20px;
    font-family: dubai;
}

.checkbox-custom-label,
.radio-custom-label {
    position: relative;
}

.checkbox-custom+.checkbox-custom-label:before,
.radio-custom+.radio-custom-label:before {
    content: '';
    background: #fff;
    border: 2px solid #2971EE;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    padding: 2px;
    margin-right: 10px;
    text-align: center;
}

.radio-custom+.radio-custom-label:before {
    border-radius: 20%;
}

.radio-custom:checked+.radio-custom-label:before {
    background: #C3F954;
    box-shadow: inset 0px 0px 0px 4px #C3F954;
}

.section1-cf form {
    border: 1px solid #C3F954;
    border-radius: 15px;
    height: 660px;
    margin-top: 100px;
    margin-left: -14px;
    padding-top: 5px;
    padding-right: 25px;
}

.section2-cf img {
    width: 175px;
    height: 263px;
    border-radius: 15px;
    border: 1px solid #2971EE;
    margin-right: 45px;
}

.section2-cf a {
    text-decoration: none;
    color: white;
    font-family: dubai;
    font-size: 20px;
    font-weight: 600;
}

.section2-cf p {
    margin-bottom: 6rem;
    max-width: 165px;
    color: white;
}

.section2-cf {
    margin-top: 100px;
    margin-left: 84px;
}

.card-style-1 {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow: hidden;
    cursor: pointer;
}

.page_wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.section1-cf,
.image-rf {
    margin-left: 43px;
}

.section1-cf,
.content-rf {
    margin-left: 45px;
}

.section2-cf h1 {
    color: #ffffff;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 50px;
    margin-bottom: 15px;
    margin-left: 700px;
}

.section2-cf h2 {
    color: #2971EE;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 25px;
    text-align: center;
    margin-bottom: 40px;
}

.section2-cf h4 {
    color: #2971EE;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 40px;
    text-align: center;
    margin-left: 560px;
    margin-top: 120px;
}

.section1-cf {
    margin-left: 26px;
    margin-right: 26px;
}



/*================FILTRE================*/
.frame {
    display: none;
    margin: auto;
    margin-top: 10px;
    overflow-y: auto;
    color: white;
    height: 6em;
    width: 10em;
    line-height: 23px;
    font-family: dubai;
}

.frame a:hover {
    color: #C3F954;
}

.frame a {
    visibility: hidden;
}

.frame {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
    overflow: hidden;
    margin: 0 auto;
}

.frame a {
    display: block;
    text-align: center;
    color: #fff;
    margin-left: 165px;
    margin-top: -10px;
}

.frame.show {
    display: flex;
    max-height: 300px; 
    overflow-y: scroll;
}


.frame.show {
    display: block;
}

.filtre-responsive a {
    text-decoration: none;
    border: 1px solid #C3F954;
    color: #C3F954;
    font-family: dubai;
    font-size: 20px;
    padding: 10px 10px 10px 10px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
}

.filtre-responsive {
    visibility: hidden;
}

.frame::-webkit-scrollbar {
    -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
    width: 20px;
}

.frame::-webkit-scrollbar:horizontal {
    height: 20px;
}

.frame::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid black;
    background-color: rgba(0, 0, 0, .5);
}


/*========================RESPONSIVE========================*/

@media only screen and (max-width: 1620px) {

    .row {
        flex-wrap: nowrap;
    }
}

@media only screen and (max-width: 1340px) {

    .section2-cf h1 {
        margin-left: 330px;
    }

    .section2-cf h4 {
        margin-left: 200px;
    }
}


@media only screen and (max-width: 992px) {

    .row {
        flex-wrap: wrap;
    }

    .section2-cf h1 {
        margin-left: 185px;
    }

    .section2-cf h4 {
        margin-left: 75px;
    }
}


@media only screen and (max-width: 900px) {

    .filtre-responsive {
        visibility: visible;
        padding-top: 100px;
    }

    .section1-cf form {
        visibility: hidden;
    }

    .section2-cf {
        margin-left: -100px;
        margin-top: 60px;
    }

    .section2-cf h1 {
        margin-left: 200px;
    }

    .section2-cf h2 {
        text-align: center;
    }


    .section2-cf h4 {
        margin-left: 200px;
    }

    .frame a {
        color: white;
        padding-top: 10px;
        visibility: visible;
    }

    .menu {
        display: none;
    }

    .menu-icon {
        display: block;
    }

    .nav {
        display: block;
    }
}


@media screen and (min-width: 901px) {
    .menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }

    .menu-icon {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .nav__list-item {
        transform: translate(-100px, 0%);
    }

    body.nav-active .nav__list-item {
        transform: translate(0px, 0%);
    }

    .frame {
        width: 100%;
        padding: 0 15px;
    }

    .frame a {
        font-size: 16px;
        padding: 10px;
    }

    
}

@media (max-width: 768px) {
    .footer .content-wrapper {
        display: block;
        text-align: center;
    }

    .footer .d-flex {
        display: block;
        text-align: center;
    }

    .footer .d-flex.justify-content-center {
        display: inline-block;
        text-align: center;
    }

    .footer .tmdb-logo {
        display: block;
        margin: 10px auto 0;
        text-align: center;
    }

    .footer .tmdb-logo img {
        margin: 0 auto;
    }

    .section2-cf h1 {
        margin-left: 270px;
    }

    .section2-cf h4 {
        margin-left: 140px;
    }
}

@media only screen and (max-width: 600px) {

    .section2-cf h4 {
        margin-left: 120px;
    }

    .section2-cf h1 {
        margin-left: 190px;
    }

}


@media only screen and (max-width: 500px) {

    .section2-cf h4 {
        margin-left: -70px;
    }

    .section2-cf h1 {
        margin-left: 20px;
    }

    .section2-cf h2 {
        margin-right: 80px;
    }

}


@media only screen and (max-width: 450px) {

    input#search-bar {
        width: 300px;
    }

}


@media only screen and (max-width: 430px) {

    .section2-cf h1 {
        text-align: left;
        margin-left: 20px;
    }

    .section2-cf {
        margin-left: -100px;
    }

    .section2-cf h2 {
        margin-left: -80px;
    }

}

@media only screen and (max-width: 810px) {

    .section1-c form {
        margin-top: 250px;
    }

    .section2-c p {
        margin-left: 200px;
    }

}