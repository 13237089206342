/*========================FICHE ACTEUR========================*/

.section1-fa {
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.container-acteur {
  max-width: 1545px;
  margin: auto;
  margin-top: 175px;
}

.section1-fa {
  position: relative;
  min-height: 919px;
  overflow: hidden;
  color: white;
}

.cover-fa img {
  height: 350px;
  max-width: 525px;
  border-radius: 15px;
  border: 1px solid #C3F954;
  margin-top: 30px;
}

.titre-fa {
  margin-top: 30px;
  text-align: center;
}

.titre-fa h1 {
  font-size: 50px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  margin: 0;
}

.résumé-fa {
  color: #C3F954;
  font-family: dubai;
  font-size: 20px;
  margin-top: 50px;
}

.biographie-fa {
  font-family: dubai;
  font-size: 20px;
  padding-right: 80px;
  margin-top: 10px;
}

.célèbre-fa {
  color: #C3F954;
  font-family: dubai;
  font-size: 20px;
  margin-top: 50px;
}

.films-fa img {
  width: 175px;
  height: 263px;
  border-radius: 15px;
  border: 1px solid #2971EE;
  margin-top: 10px;
}

.détails-fa h4 {
  color: #2971EE;
  font-size: 16px;
  font-family: dubai;
  margin-top: 15px;
}

.détails-fa p {
  font-size: 16px;
  font-family: dubai;
  margin-top: -8px;
}

.films-fa .col {
  min-width: 150px;
}


/*========================RESPONSIVE========================*/

@media only screen and (max-width: 1620px) {
  .cover-fa {
      margin-left: 26px;
      display: block;
  }

  .row {
      flex-wrap: nowrap;
  }

  .container-acteur {
      margin: 175px 0px 0px 0px;
  }
}


@media only screen and (max-width: 1250px) {

  .films-fa img {
      width: 140px;
      height: 210px;
  }

}


@media only screen and (max-width: 992px) {

  .row {
      flex-wrap: wrap;
  }

  .img-acteur img {
      width: 350px;
      height: 520px;
  }

  .cover-fa {
      margin-left: 0;
  }

  .biographie-fa {
      text-align: center;
      padding: 40px;
  }

  .résumé-fa,
  .célèbre-fa {
      text-align: center;
  }

  .films-fa {
      margin-left: 45px;
  }

  .films-fa img {
      width: 175px;
      height: 263px;
  }

}


@media only screen and (max-width: 900px) {

  .container-acteur {
      margin: 70px 0px 0px 0px;
  }

}


@media only screen and (max-width: 768px) {

  .films-fa {
      margin-left: 110px;
  }

}


@media (max-width: 768px) {
  .footer .content-wrapper {
      display: block;
      text-align: center;
  }

  .footer .d-flex {
      display: block;
      text-align: center;
  }

  .footer .d-flex.justify-content-center {
      display: inline-block;
      text-align: center;
  }

  .footer .tmdb-logo {
      display: block;
      margin: 10px auto 0;
      text-align: center;
  }

  .footer .tmdb-logo img {
      margin: 0 auto;
  }
}


@media only screen and (max-width: 700px) {

  .films-fa {
      margin-left: 85px;
  }

}

@media only screen and (max-width: 630px) {

  .films-fa {
      margin-left: 60px;
  }

}


@media only screen and (max-width: 575px) {

  .films-fa {
      margin-left: 40px;
  }
}


@media only screen and (max-width: 550px) {

  input#search-bar {
      width: 400px;
  }

}


@media only screen and (max-width: 520px) {

  .films-fa {
      margin-left: 20px;
  }
}


@media only screen and (max-width: 430px) {

  .films-fa {
      margin-left: 7px;
  }
}


@media only screen and (max-width: 414px) {

  .films-fa {
      margin-left: 0px;
  }
}