* {
  box-sizing: border-box;
}

body {
  font-family: dubai;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  background-image: url('../../public/images/fond-n.png');
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: black;
}

a {
  text-decoration: none;
}

.logo-fim-header {
  display: flex;
  justify-content: center;
  margin-top: -63px;
  margin-bottom: 8px;
}








































@media screen and (max-width: 1700px) {
  .header-accueil .splide__img img {
    height: auto;
  }
}

@media screen and (max-width: 1580px) {
  .header-accueil .splide__list {
    height: 800px !important;
  }
}

@media screen and (max-width: 1440px) {
  .header-accueil .splide__list {
    height: 700px !important;
  }
}

.recherche-body .row {
  --bs-gutter-x: -0.5 rem !important;
  width: 98% !important;
}



@media only screen and (min-width: 1200px) and (max-width: 1350px) {

  /*----------------paragraphe-page-recherche---------------------*/
  .recherche-body img {
    width: 200px;

  }

  .detail-categories .card {
    width: 180px;
    height: 265px;
  }
}




@media screen and (max-width: 1399px) {

  /*----------------card-page-categorie---------------------*/
  .detail-categories .card {
    width: 175px;
    height: 260px;
  }
}


@media screen and (max-width: 1360px) {

  /*----------------card-page-categorie---------------------*/
  .header_categorie_1 .movie-card {
    width: 200px;
  }
}

@media screen and (max-width: 1199px) {

  /*----------------card-page-categorie---------------------*/
  .header_categorie_1 .movie-card {
    width: 225px;
  }

  .detail-film img {
    /*----------------cover-page-fich-film---------------------*/
    margin-top: 15px;
  }
}



@media screen and (max-width: 1260px) {
  .header-accueil .splide__list {
    height: 600px !important;
  }
}

@media screen and (max-width: 1200px) {
  .detail-categories .card {
    margin-left: 40px;
  }
}


@media only screen and (min-width: 992px) and (max-width: 1200px) {

  /*----------------paragraphe-page-recherche---------------------*/
  .recherche-body p {
    width: 90%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
}




@media screen and (max-width: 1070px) {
  .header-accueil .splide__list {
    height: 500px !important;
  }
}


@media screen and (max-width: 992px) {

  /*----------------cover-page-fich-film---------------------*/
  .etudiant h1 {
    margin: 0px 20px;
    text-align: center;
    padding-top: 20px;
  }

  .header_a-propos p,
  .etudiant p {
    padding-bottom: 10px;
    margin: 0px 20px;
  }
}

@media screen and (max-width: 990px) {

  /*----------------cover-page-fich-film---------------------*/
  .detail-film img {
    margin-top: 25px;
  }

}


@media screen and (max-width: 900px) {
  .header-accueil .splide__list {
    height: 450px !important;
  }
}

@media screen and (max-width: 820px) {
  .header-accueil .splide__list {
    height: 400px !important;
  }
}

@media screen and (max-width: 767px) {

  .detail-film,
  .detail-film img {
    margin-top: 40px !important;
  }

  .detail-film img {
    margin-bottom: 40px !important;
  }


}





@media screen and (max-width: 730px) {
  .header-accueil .splide__list {
    height: 350px !important;
  }
}

@media screen and (max-width: 653px) {
  .header-accueil .splide__list {
    height: 300px !important;
  }

  .a-footer a {
    width: 140px;
    font-size: 16px;
  }

  footer {
    padding: 0px 0px !important;
  }
}


@media screen and (max-width: 653px) {

  .btn-tendances {
    padding: 10px 15px 10px 15px;
  }

}



@media screen and (max-width: 550px) {
  .header-accueil .splide__list {
    height: 270px !important;
  }
}

@media screen and (max-width: 540px) {
  .header_acteur .col-6 {
    margin-left: -10px;
    margin-bottom: 50px !important;
    width: 48%;
  }

  .header_categorie_1 .col-6 {
    margin-left: -3px;
    width: 48%;
  }

  .btn-tendances a {
    font-size: 14px;
  }

  .accueil-container h2 {
    font-size: 30px;
  }

  .a-footer {
    font-size: 25px;
    width: 185px;
    padding-top: 7px;
  }

  .a-footer-index {
    font-size: 25px;
    width: 185px;
    padding-top: 14px;
  }
}




@media screen and (max-width: 430px) {
  .header-accueil .splide__list {
    height: 241.88px !important;
  }

  .recherche-body img {
    width: 200px;
  }

  .header_categorie_1 .movie-card {
    width: 200px;
  }

  .btn-tendances {
    margin-left: 25px;
  }

  .etudiant h1 {
    font-size: 40px;
  }

  .a-footer,
  .a-footer-index {
    font-size: 20px;
    width: 120px;
    padding-top: 12px;
  }

  .a-footer-index {
    padding-top: 17px;
  }

  .header_categorie_1 .row {
    --bs-gutter-x: 1.25rem;
  }

  .detail-categories .row {
    --bs-gutter-x: 1.rem;
  }

}


@media screen and (max-width: 414px) {
  .header-accueil .splide__list {
    height: 232.88px !important;
  }

  .btn-tendances {
    padding: 10px 20px 10px 20px;
  }
}