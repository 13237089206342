.footer i {
  color: white;
  margin-top: -30px
}

.footer p {
  color: white;
  font-family: dubai;
  display: inline;
  margin-left: 15px;
  margin-top: -30px;
}

.footer img {
  height: 35px;
  width: 200px;
  margin-right: 20px;
  margin-top: -50px;
}

.footer {
  margin-top: 130px;
}


@media (max-width: 768px) {
  .footer .content-wrapper {
      display: block;
      text-align: center;
  }

  .footer .d-flex {
      display: block;
      text-align: center;
  }

  .footer .d-flex.justify-content-center {
      display: inline-block;
      text-align: center;
  }

  .footer .tmdb-logo {
      display: block;
      margin: 10px auto 0;
      text-align: center;
  }

  .footer .tmdb-logo img {
      margin: 0 auto;
  }
}