.section-all-ap {
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}


.section1-ap,
.section2-ap {
    max-width: 1550px;
    margin: auto;
}

.section1-ap h1 {
    text-align: center;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 50px;
    color: white;
    padding-top: 200px;
}

.section2-ap h1 {
    text-align: center;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 50px;
    color: white;
    margin-top: 100px;
}

.section2-ap h2 {
    text-align: center;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 30px;
    color: #2971EE;
    margin-top: 100px;
}

.section1-ap p {
    margin-top: 40px;
    color: white;
    font-family: dubai;
    font-size: 20px;
    text-align: center;
    padding: 0px 20px 0px 20px;
    line-height: 25px;
}

.section2-ap .row {
    max-width: 1920px;
}

.section2-ap p {
    color: white;
    font-size: 20px;
    font-family: dubai;
    margin-top: 40px;
    text-align: center;
    padding: 20px;
}

.row-1 .row {
    flex-wrap: wrap !important;
}





/*========================RESPONSIVE========================*/

@media screen and (max-width: 900px) {
    .menu {
        display: none;
    }

    .menu-icon {
        display: block;
    }

    .nav {
        display: block;
    }

    .section1-ap h1 {
        padding-top: 75px;
    }
}
