/* src/pages/SearchMovie.css */
.section-all {
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
  }
  
  .carrousel-recherche {
    position: relative;
  }
  
  .splide__img img {
    width: 100%;
    height: auto;
    border-radius: 15px;
  }
  
  .container-r {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 20px;
  }
  
  .search-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .search-top {
    display: flex;
    align-items: center;
  }
  
  input#search-bar {
    width: 500px;
    height: 40px;
    padding: 0 20px;
    font-size: 15px;
    border: 2px solid #C3F954;
    outline: none;
    color: #fff;
    border-radius: 10px;
    font-family: dubai;
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.4);
  }

  input#search-bar-rf {
    width: 500px;
    height: 40px;
    padding: 0 20px;
    font-size: 15px;
    border: 2px solid #C3F954;
    outline: none;
    color: #fff;
    border-radius: 10px;
    margin-left: 25px;
    font-family: dubai;
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.4);
}

.search-container-rf i {
    font-size: 25px;
    margin-left: -40px;
    margin-top: 180px;
    cursor: pointer;
    opacity: 99%;
}
  
  input#search-bar::placeholder {
    color: #fff;
  }
  
  .search-top button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: #fff;
    font-size: 25px;
    margin-top: -250px;
    z-index: 1;
    margin-left: -45px;
  }
  
  .section1-rf {
    margin-top: 75px;
    margin-left: 30px;
  }
  
  .card-style-1 {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 30px;
  }
  
  .page_wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  
  .card-style-1 p {
    text-decoration: none;
    color: white;
  }
  
  .image-rf img {
    width: 175px;
    height: 263px;
    border-radius: 15px;
    border: 1px solid #2971EE;
    margin-right: 47px;
  }
  
  .content-rf {
    text-align: left;
    font-family: dubai;
    font-size: 20px;
    font-weight: 600;
  }

  .content-rf {
    width: 100%;
    max-width: 175px; 
}

  
  /*========================RECHERCHE========================*/

.container-r {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.image-r {
    filter: brightness(40%);
}

.search-container {
    display: flex;
    justify-content: center;
}

.search-top {
    display: flex;
    align-items: center;
    position: relative;
}

input#search-bar {
    width: 700px;
    height: 70px;
    margin-top: -250px;
    padding: 0 20px;
    font-size: 15px;
    font-family: dubai;
    color: #ffffff;
    border: 2px solid #C3F954;
    outline: none;
    border-radius: 15px;
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.4);
}

input#search-bar:focus {
    border: 2px solid #C3F954;
    transition: 0.35s ease;
    color: #ffffff;
}

input#search-bar::placeholder {
    transition: opacity 0.45s ease;
    opacity: 1;
    color: #000000;
    font-family: dubai;
}

input#search-bar:focus::placeholder {
    opacity: 1;
}

.search-container button {
    font-size: 25px;
    position: absolute;
    right: 20px;
    cursor: pointer;
    color: #2971EE;
}

.container-r {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.container-r form {
    border: 0px;
    height: 0px;
}

.section-all form {
    border: 0px;
    height: 0px;
}


/*================CAROUSEL-RECHERCHE================*/

.carrousel-recherche.splide:not(.is-active) {
    visibility: visible;
}

.carrousel-recherche .splide__wrapper {
    position: relative;
}

.carrousel-recherche .splide__slide {
    margin-bottom: 4rem;
    position: relative;
    overflow: hidden;

}

.carrousel-recherche .splide__slide .is-active img {
    transform: scale(1);
}

.carrousel-recherche .splide__img {
    width: 100%;
    height: 100vh;
}

.carrousel-recherche .splide__img img {
    transition: transform 1.5s ease-out;
    display: block;
    aspect-ratio: 16 / 9;
    width: 100%;
    height: auto;
}


@media only screen and (max-width: 750px) {

  input#search-bar {
      width: 600px;
  }

}


@media only screen and (max-width: 650px) {

  input#search-bar {
      width: 500px;
  }

}


@media only screen and (max-width: 550px) {

  input#search-bar {
      width: 400px;
  }

}


@media only screen and (max-width: 450px) {

  input#search-bar {
      width: 300px;
  }

}

@media only screen and (max-width: 850px) {

  .carrousel-recherche .splide__img img {
      transition: transform 1.5s ease-out;
      display: block;
      aspect-ratio: 2/3;
      width: 850px;
      height: 920px;
  }
}


@media only screen and (max-width: 768px) {

  .films-fa {
      margin-left: 110px;
  }

}

@media only screen and (max-width: 575px) {

  .section1-rf a {
      margin-left: 62px;
  }
}




@media only screen and (max-width: 520px) {

  .section1-rf img {
      margin-right: 35px;
      margin-left: 12px;
  }

  .section1-rf p {
      margin-left: 10px;
  }

  input#search-bar-rf {
      width: 430px;
  }


}


@media only screen and (max-width: 430px) {

  .section1-rf img {
      margin-right: 25px;
  }

  .section1-rf {
      margin-left: 6px;
  }

  .section1-rf p {
      margin-left: 15px;
  }

  input#search-bar-rf {
      width: 380px;
  }

}


@media only screen and (max-width: 414px) {

  .section1-rf img {
      margin-right: 12px;
      margin-left: 13px;
  }

  .section1-rf p {
      margin-left: 13px;
  }

  input#search-bar-rf {
      width: 362px;
  }
}