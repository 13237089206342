/*----------------------------Accueil-------------------------------*/
.video-section .item{
  opacity:0.4;
  transition:.4s ease all;
  margin:0 20px;
  transform:scale(.8);
}
.video-section .active .item{
  opacity:1;
  transform:scale(1);
} 
.owl-carousel .youtube-embed {
  position: relative;
  padding-bottom: 56%;
  width: 100%;
  text-align: left;
}


/*--------------------------------------------
Slider
---------------------------------------------*/

.header-accueil .splide__list {
  height: 881px !important;
}

.header-accueil .splide:not(.is-active) {
  visibility: visible;
}
.header-accueil .splide__wrapper {
  position: relative;
}

.header-accueil .splide__slide {
  width: 100%;
  height: 1080px;
  margin-bottom: 4rem;
  position: relative;
  overflow: hidden;
}
.header-accueil .splide__slide.is-active img {
  transform: scale(1);
}
.header-accueil .splide__img {
  width: 100%;
  height: 100vh;
}
.header-accueil .splide__img img {
  transform: scale(1.1);
  transition: transform 1.5s ease-out;
  display: block;
  width: 1920px;
  height: 100%;
}


/*-----section-2----*/

.accueil-container  {
  padding-top: 80px;
}

.tendances-a{
  padding-left: 20px;
}

.tendances-carrousel h2{
  color: #2971EE;
  font-size: 40px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  margin-left: 40px;
}


/*--------carrousel-tendances--------*/

.accueil-body .splide__arrow, .accueil-body .splide__pagination, .carrousel_acteur-film .splide__arrow, .carrousel_acteur-film .splide__pagination {
  display: none !important;
}

.accueil-body .splide, .carrousel_acteur-film .splide {
  margin-inline: auto !important;
}

.accueil-body .splide__track, .carrousel_acteur-film .splide__track {
  overflow: initial !important; 
}

.accueil-body .splide__pagination, .carrousel_acteur-film .splide__pagination{
  bottom: initial !important; 
}

.accueil-body .card, .carrousel_acteur-film .card {
  background-color: transparent;
  width: 200px !important;
  height: 445px !important;
  border-radius: 4px !important;
  overflow: hidden !important;
  cursor: pointer !important;
  margin-right: 15px !important;
}

.accueil-body .card__content{
  padding: 10px !important;
  min-height: 115px;
}

.accueil-body .card__title, .carrousel_acteur-film .card__title  {
  color: white;
  font-family: dubai;
  text-align: left;
  font-size: 20px;
}

.tendances-carrousel, .carrousel_acteur-film .tendances-carrousel {
  padding-top: 50px !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

#splide2, #splide3, #splide4, #splide5, #splide6 {
  max-width: 100% !important;
  margin-inline: auto !important; 
}

.accueil-body .splide__slide{
  width: 200px !important;
}

.accueil-body .card2 img{
  width: 100%;
  height: 297px;
  border: 1px solid #2971EE;
  border-radius: 15px;
}

.accueil-body h3 { 
  line-height: 20px; 
  font-size: 16px;
}



/*========================RESPONSIVE========================*/

@media only screen and (max-width: 1600px) {

  .carrousel-accueil .splide__img img {
      max-width: 1600px;
      height: auto;
  }

  .carrousel-recherche .splide__img img {
      max-width: 1600px;
      height: auto;
  }
}


